import React, { useRef, useCallback } from "react";
import { Flex, FlexLeftCol, Colors } from "styles/global-style";
import Input from "components/Input";
import Textarea from "components/Textarea";
import PillDropdown from "components/PillDropdown";
import useKey from "utils/KeyListener";

import trashcan from "imgs/trashcan.png";

export default function EditTask({ submitTask, deleteTask, editingTask, users }) {
  const titleRef = useRef();
  const ownerRef = useRef();
  const estimateRef = useRef();
  const descriptionRef = useRef();

  useKey("Enter", false, (e) => {
    focusNext(e);
  });

  useKey("Enter", true, () => {
    submit();
  });

  const focusNext = useCallback(
    (e) => {
      if (e.target === titleRef.current) ownerRef.current.focus();
      else if (e.target === ownerRef.current.getRef()) estimateRef.current.focus();
      else if (e.target === estimateRef.current.getRef()) descriptionRef.current.focus();

      if (e.target !== descriptionRef.current) e.preventDefault();
    },
    [titleRef, ownerRef, estimateRef, descriptionRef]
  );

  const submit = useCallback(
    (props, keepOpen) => {
      submitTask(
        {
          id: editingTask.task.id,
          title: titleRef.current.value.trim(),
          description: descriptionRef.current?.value?.trim() || "",
          estimate: props?.estimate
            ? props.estimate === "remove"
              ? null
              : typeof props.estimate === "number"
              ? props.estimate
              : editingTask.task.estimate
            : editingTask.task.estimate,
          person_id: props?.ownerId
            ? props.ownerId === "remove"
              ? null
              : typeof props.ownerId === "number"
              ? props.ownerId
              : editingTask.task.person_id
            : editingTask.task.person_id,
        },
        keepOpen
      );
    },
    [submitTask, editingTask, titleRef]
  );

  const removeTask = useCallback(() => {
    if (deleteTask && editingTask.task.id > -1) {
      if (window.confirm("Are you sure you want to delete this task?")) {
        deleteTask(editingTask.task.id);
      }
    }
  }, [deleteTask, editingTask]);

  return (
    <FlexLeftCol gap={4}>
      <Flex>
        <Input
          ref={titleRef}
          placeholder="Task title here..."
          autoFocus
          left
          value={editingTask?.task?.title}
          noborder
          customStyle={{ padding: 0, border: "none", outline: "none", fontSize: 28, fontWeight: "bold" }}
        />
      </Flex>

      {editingTask.task.id > -1 && (
        <img alt="delete" src={trashcan} style={{ position: "absolute", height: 20, top: 20, right: 20, opacity: 0.7, cursor: "pointer" }} onClick={() => removeTask()} />
      )}

      <Flex gap={6} style={{ marginBottom: 20 }}>
        <PillDropdown
          ref={ownerRef}
          list={users.map((val) => ({
            value: !val.lastname ? val.firstname : val.firstname + " " + val.lastname.charAt(0),
            id: val.id,
          }))}
          selectedIdx={users.findIndex((item) => item.id === editingTask?.task?.owner_id)}
          placeholder={"Owner"}
          onSelect={(item) => {
            submit({ ownerId: item.id }, true);
          }}
          onClear={() => {
            submit({ ownerId: "remove" }, true);
          }}
          pillStyle={{
            background: Colors.GREEN,
            color: "white",
            border: "1px solid " + Colors.GREEN,
            borderRadius: 18,
          }}
        />
        <PillDropdown
          ref={estimateRef}
          regexp={/\d+[dh]?/g} // Only numbers
          selectedIdx={editingTask?.task?.estimate ? (editingTask?.task?.estimate % 8 === 0 ? 0 : 1) : 0}
          hasPrefixNumber
          prefixNumber={
            editingTask?.task?.estimate
              ? editingTask?.task?.estimate <= 8 || editingTask?.task?.estimate % 8 !== 0
                ? editingTask?.task?.estimate
                : editingTask?.task?.estimate / 8
              : null
          }
          list={[{ value: "days" }, { value: "hrs" }]}
          placeholder={"Estimate"}
          onSelect={(item, postFix) => {
            // Always store estimate in hrs. I.e. 2 days = 2 * 8 hrs.
            const _estimate = postFix === "hrs" ? parseInt(item.value, 10) : parseInt(item.value, 10) * 8;
            submit({ estimate: _estimate }, true);
          }}
          onClear={() => {
            submit({ estimate: "remove" }, true);
          }}
          pillStyle={{
            background: "transparent",
            color: "gray",
            borderRadius: 4,
          }}
        />
      </Flex>

      <Textarea
        ref={descriptionRef}
        placeholder="Task details goes here..."
        onChangeDelay={() => {
          submit(null, true);
        }}
        left
        value={editingTask?.task?.description}
        customStyle={{ padding: 0, minHeight: 50, fontSize: 13 }}
      />

      {/*<Flex>
        <Button text="Get AI estimate" callback={getGpt} color={"purple"} width={100} />
        {gpt && <div style={{ color: "black" }}>{gpt}</div>}
      </Flex>*/}
    </FlexLeftCol>
  );
}
