import { useEffect, useState, forwardRef, useCallback, useRef, useImperativeHandle } from "react";
import { captionStyle } from "styles/global-style";

import xmark from "imgs/xmark.png";

export default forwardRef(function PillDropdown(props, ref) {
  const { placeholder, onSelect, onClear, selectedIdx, list: initialList, caption, light, pillStyle, hasPrefixNumber, prefixNumber: defaultPrefix } = props;

  const [selected, setSelected] = useState(selectedIdx > -1 ? selectedIdx : -1);
  const [displayValue, setDisplayValue] = useState(null);
  const [filteredItems, setFilteredItems] = useState(initialList || []);
  const [highlightedIndex, setHighlightedIndex] = useState(-1); // Tracks highlighted item in dropdown
  const [hasFocus, setHasFocus] = useState(false);
  const [width, setWidth] = useState(40);

  const containerRef = useRef();
  const inputRef = useRef();
  const originalPlaceholder = useRef(placeholder);
  const hasChanged = useRef(false);
  const hiddenInputRef = useRef();
  const userValue = useRef(defaultPrefix);

  //----------------------------------------------------

  useEffect(() => {
    setWidth(hiddenInputRef.current.getBoundingClientRect().width + 15);
  }, [displayValue, placeholder]);

  //----------------------------------------------------

  const update = useCallback(() => {
    let filteredList;

    if (hasChanged.current) {
      if (userValue.current) {
        if (!hasPrefixNumber) {
          filteredList = initialList.filter((item) => item.value.toLowerCase().includes(userValue.current.toLowerCase()));
        } else {
          // User input is prefix and list selection is postfix
          filteredList = initialList.map((item) => {
            return { value: userValue.current + " " + item.value.toLowerCase() };
          });
          filteredList = filteredList.filter((item) => item.value.toLowerCase().includes(userValue.current));
        }
      } else {
        // Show the full list when displayValue is empty
        filteredList = initialList;
      }

      if (!hasPrefixNumber) {
        setHighlightedIndex(userValue.current?.trim() ? filteredList.findIndex((item) => item.value.toLowerCase().includes(userValue.current.toLowerCase())) : -1);
      } else if (selected > -1) {
        setHighlightedIndex(selected);
      } else {
        setHighlightedIndex(-1);
      }
    } else if (userValue.current) {
      if (!hasPrefixNumber) {
        // Exclude the selected value and filter based on displayValue
        filteredList = initialList.filter((item, idx) => selected !== idx);
      } else {
        // User input is prefix and list selection is postfix
        filteredList = initialList.map((item) => {
          return { value: userValue.current + " " + item.value.toLowerCase() };
        });
        filteredList = filteredList.filter((item) => item.value.toLowerCase().includes(userValue.current));
      }
      setHighlightedIndex(-1);
    } else {
      // Show the full list if no value is selected
      filteredList = initialList;
    }

    setFilteredItems(filteredList);

    if (!hasPrefixNumber) {
      if (hasChanged.current) {
        setDisplayValue(userValue.current);
      } else {
        setDisplayValue(selected > -1 ? initialList[selected].value : null);
      }
    } else {
      if (hasFocus) {
        setDisplayValue(userValue.current);
      } else {
        setDisplayValue(userValue.current ? userValue.current + " " + initialList[selected].value : null);
      }
    }
  }, [initialList, hasChanged, selected, hasPrefixNumber, hasFocus, setHighlightedIndex, setDisplayValue, setFilteredItems, userValue]);

  // Handle item selection from dropdown
  const handleSelect = useCallback(
    (item) => {
      const value = hasPrefixNumber && userValue.current ? item.value.split(" ")[1].trim() : item.value.trim();
      const _selected = initialList.findIndex((val) => val.value === value);
      setSelected(_selected);
      if (onSelect) onSelect(item, initialList[_selected].value);

      hasChanged.current = false;
      inputRef.current.blur(); // Remove focus from input field
    },
    [setSelected, initialList, hasChanged, onSelect, inputRef, hasPrefixNumber]
  );

  const removeSelected = () => {
    setSelected(-1);
    setDisplayValue(null);
    if (onClear) onClear();
  };

  // Handle keydown events for navigation
  const handleKeyDown = useCallback(
    (e) => {
      if (e.key === "ArrowDown") {
        // Move the highlight down
        setHighlightedIndex((prevIndex) => (prevIndex < filteredItems.length - 1 ? prevIndex + 1 : 0));
      } else if (e.key === "ArrowUp") {
        // Move the highlight up
        setHighlightedIndex((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : filteredItems.length - 1));
      } else if (e.key === "Enter" && highlightedIndex >= 0) {
        // Select the highlighted item on Enter key
        if (highlightedIndex > -1) {
          handleSelect(filteredItems[highlightedIndex]);
          // if (!hasPrefixNumber) handleSelect(filteredItems[highlightedIndex]);
          // else handleSelect({ value: userValue.current + " " + filteredItems[highlightedIndex].value });
        }
      } else if (e.key === "Escape") {
        e.stopPropagation();
        inputRef.current.blur();
      }
    },
    [filteredItems, handleSelect, highlightedIndex, inputRef]
  );

  useImperativeHandle(
    ref,
    () => ({
      getSelectedId: () => {
        return initialList[selected].id;
      },
      focus: () => {
        inputRef.current.focus();
      },
      getRef: () => {
        return inputRef.current;
      },
    }),
    [initialList, selected, inputRef]
  );

  useEffect(() => {
    update();
  }, [selected, displayValue, update, hasFocus]);

  return (
    <div ref={containerRef} style={{ position: "relative", display: "flex", alignItems: "center" }}>
      {caption && <div style={{ ...captionStyle, color: light ? "#ffffff88" : "#999" }}>{caption}</div>}
      <input
        ref={inputRef}
        className={light ? "input-white" : "input"}
        placeholder={placeholder}
        style={{
          fontSize: 11,
          padding: "2px 4px",
          textAlign: "center",
          border: light ? "1px solid #ffffff33" : "1px solid #bbb",
          outline: "none",
          width: width,
          minWidth: 20,
          cursor: hasFocus ? "initial" : "pointer",
          paddingRight: hasFocus && selected > -1 ? 10 : 4,
          filter: selected > -1 ? "none" : "grayscale(100%)",
          opacity: selected > -1 ? 1 : 0.7,
          ...pillStyle,
        }}
        onChange={(event) => {
          if (hasPrefixNumber) {
            userValue.current = event.target.value.match(/\d{0,3}/)[0];
          } else {
            userValue.current = event.target.value.trim();
          }
          hasChanged.current = true;
          update(1);
        }}
        onFocus={(event) => {
          if (hasPrefixNumber) {
            userValue.current = event.target.value.match(/\d{0,3}/)[0];
          }
          inputRef.current.placeholder = "";
          hasChanged.current = false;
          setHasFocus(true);
        }}
        onBlur={() => {
          if (inputRef && !inputRef.current.value.trim()) inputRef.current.placeholder = originalPlaceholder?.current || "";
          setHasFocus(false);
          hasChanged.current = false;
        }}
        value={displayValue || ""}
        onKeyDown={handleKeyDown} // Add keydown listener
      />
      {hasFocus && selected > -1 && (
        <img
          alt="X"
          src={xmark}
          style={{ position: "absolute", height: 7, width: 7, right: 6, top: 6, opacity: 0.4, cursor: "pointer", zIndex: 100 }}
          onMouseDown={() => {
            removeSelected();
          }}
        />
      )}

      {/* Dropdown list */}
      {hasFocus && filteredItems.length > 0 && (
        <div
          style={{
            position: "absolute",
            top: caption ? 30 : 20,
            display: "flex",
            flexDirection: "column",
            zIndex: 100,
            backgroundColor: "#fff",
            border: "1px solid #ccc",
            marginTop: "2px",
            borderRadius: 8,
            padding: "3px 4px",
            boxSizing: "border-box",
          }}
        >
          {filteredItems.map((item, i) => (
            <ListItem
              key={"item" + i}
              value={item.value}
              id={item.id}
              isHighlighted={i === highlightedIndex} // Highlight current item
              selectCb={(e) => {
                e.preventDefault();
                e.stopPropagation();
                handleSelect(item);
              }}
            />
          ))}
        </div>
      )}

      {/* Hidden field just for measuring width */}
      <span
        ref={hiddenInputRef}
        style={{
          display: "inline-block",
          position: "absolute",
          fontSize: 11,
          visibility: "hidden",
        }}
      >
        {displayValue ? displayValue : placeholder}
      </span>
    </div>
  );
});

const ListItem = ({ value, isHighlighted, selectCb }) => {
  const [hover, setHover] = useState(false);

  return (
    <div
      style={{
        padding: "4px 12px",
        background: isHighlighted || hover ? "#00000011" : "transparent",
        cursor: "pointer",
        color: "black",
        borderRadius: 4,
        whiteSpace: "nowrap",
        fontSize: 13,
      }}
      onMouseDown={selectCb}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      {value}
    </div>
  );
};
