import { useState, useLayoutEffect, useEffect, forwardRef, useCallback, useRef } from "react";
import { textareaStyle, captionStyle } from "styles/global-style";

export default forwardRef(function Textarea(props, ref) {
  const { placeholder, onChange, onChangeDelay, autoFocus, type, caption, white, value, customStyle } = props;

  const [textValue, setTextValue] = useState(value ? value : "");

  const originalPlaceholder = useRef(placeholder);
  const submitTimer = useRef();
  const hasChanged = useRef(false);

  //----------------------------------------------------

  const clearPlaceholder = useCallback(() => {
    if (ref?.current === document.activeElement) ref.current.placeholder = "";
  }, [ref]);

  const submit = useCallback(
    (event, notimer) => {
      if (submitTimer.current) clearTimeout(submitTimer.current);
      if (onChange) {
        onChange(event);
        hasChanged.current = false;
      } else if (onChangeDelay) {
        if (!notimer) {
          submitTimer.current = setTimeout(() => {
            onChangeDelay(event);
            hasChanged.current = false;
          }, 6000);
        } else {
          // On blur or leaving page
          onChangeDelay(event);
          hasChanged.current = false;
        }
      }
    },
    [onChange, onChangeDelay]
  );

  //----------------------------------------------------

  useEffect(() => {
    // AutoFocus doesn't trigger the onFocus
    clearPlaceholder();
  }, [clearPlaceholder]);

  useEffect(() => {
    return () => clearTimeout(submitTimer.current);
  }, []);

  const adjustHeight = useCallback(() => {
    ref.current.style.height = "inherit";
    ref.current.style.height = `${ref.current.scrollHeight}px`;
  }, [ref]);

  useLayoutEffect(adjustHeight, [adjustHeight]);

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      {caption && <div style={{ ...captionStyle, color: white ? "#fff" : "#999" }}>{caption}</div>}
      <textarea
        ref={ref}
        className={white ? "input-white" : "input"}
        placeholder={placeholder}
        style={{ ...textareaStyle, color: white ? "#fff" : "initial", ...customStyle, resize: "none" }}
        onChange={(event) => {
          setTextValue(event.target.value);
          adjustHeight();
          hasChanged.current = true;
          submit(event);
        }}
        onFocus={() => {
          clearPlaceholder();
        }}
        onBlur={(event) => {
          if (!ref?.current.placeholder.trim()) ref.current.placeholder = originalPlaceholder?.current || "";
          if (hasChanged.current) submit(event, true);
        }}
        autoFocus={autoFocus}
        type={type ? type : ""}
        value={textValue}
      />
    </div>
  );
});
