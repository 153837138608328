import React, { useState, useEffect, useRef } from "react";
import { FlexSpaceBetweenVert, Colors, FlexSpaceBetween } from "styles/global-style";
import { Draggable } from "@hello-pangea/dnd";

export default function Task({ indexPath, task, clickTaskCb, user }) {
  const [height, setHeight] = useState(50);

  const titleRef = useRef();
  const pillsRef = useRef();

  const SPACE = 6;

  const getStyle = (draggableStyle) => ({
    background: "#fff",
    borderRadius: 5,
    padding: "9px 11px",
    margin: 3,
    userSelect: "none",
    width: 130,
    height: Math.max(height, 50),
    cursor: "pointer",
    boxShadow: "rgba(0, 0, 0, 0.667) 1px 3px 5px",
    color: "black",

    // styles we need to apply on draggables
    ...draggableStyle,
  });

  useEffect(() => {
    let _height = 0;
    _height += titleRef.current?.getBoundingClientRect().height;
    _height += pillsRef.current ? pillsRef.current?.getBoundingClientRect().height : 0;
    _height += SPACE;

    setHeight(_height);
  }, [setHeight, titleRef, pillsRef, SPACE]);

  return (
    <Draggable draggableId={indexPath.join("-")} index={indexPath[3]} type="task">
      {(provided, snapshot) => (
        <div {...provided.draggableProps} {...provided.dragHandleProps} ref={provided.innerRef} style={getStyle(provided.draggableProps.style)} onClick={() => clickTaskCb(task)}>
          <FlexSpaceBetweenVert gap={0}>
            <div ref={titleRef} style={{ color: "#000", fontSize: 12, fontWeight: 500 }}>
              {task.title}
            </div>
            <div ref={pillsRef}>
              <FlexSpaceBetween gap={2}>
                {user && <Pill value={user.firstname + (user.lastname ? " " + user.lastname.charAt(0) : "")} background={Colors.GREEN} />}
                {task.estimate && (
                  <div style={{ fontWeight: "bold", color: "#555", fontSize: 11 }}>
                    {task.estimate > 8 ? Math.floor(task.estimate / 8) + "d" + (task.estimate % 8 !== 0 ? " " + (task.estimate % 8) + "h" : "") : task.estimate + "h"}
                  </div>
                )}
              </FlexSpaceBetween>
            </div>
          </FlexSpaceBetweenVert>
        </div>
      )}
    </Draggable>
  );
}

const Pill = ({ value, background, color, border, bold }) => {
  return (
    <div
      style={{
        fontSize: 10,
        display: "inline-flex",
        justifyContent: "center",
        background: background ? background : "#999",
        color: color ? color : "white",
        border: border ? border : "1px solid " + (background ? background : "#999"),
        borderRadius: 18,
        padding: "2px 6px",
        minWidth: 20,
        fontWeight: bold ? "bold" : "initial",
      }}
    >
      {value}
    </div>
  );
};
