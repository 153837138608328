import React, { useState } from "react";
import { Flex, FlexLeftColNoGap, PADDING } from "styles/global-style";
import ButtonTiny from "components/ButtonTiny";
import Task from "./Task";
import { Droppable } from "@hello-pangea/dnd";

import check from "imgs/check.png";

export default function Column({ indexPath, col, index, storyIndex, storyId, width, isFirst, showAdd, addTaskCb, editTaskCb, users, isEndColumn }) {
  const [isMinimized, setIsMinimized] = useState(isEndColumn);

  const getStyle = (isDraggingOver) => ({
    background: isDraggingOver ? "#ffffff22" : "none",
    display: "flex",
    padding: `5px ${PADDING / 2}px`,
    paddingLeft: 4,
    flexWrap: "wrap",
    alignItems: "start",
    flexGrow: 1,
    minHeight: 74,
  });

  return (
    <FlexLeftColNoGap
      style={{
        minWidth: 180,
        width: width + "%",
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          paddingLeft: 6,
          background: "#ffffff44",
          backdropFilter: "blur(2px)",
          opacity: 0.6,
          fontSize: 10,
          height: 20,
          borderRadius: 2,
          boxShadow: "#000000 1px 1px 3px",
          fontWeight: "bold",
          userSelect: "none",
        }}
      >
        {col.title}
      </div>

      <Droppable droppableId={indexPath.join("-")} type="task">
        {(provided, snapshot) => (
          <div
            key={indexPath.join("-")}
            {...provided.droppableProps}
            ref={provided.innerRef}
            style={getStyle(snapshot.isDraggingOver)}
            onClick={() => {
              if (isEndColumn) setIsMinimized(false);
            }}
            onMouseLeave={() => {
              if (isEndColumn && !snapshot.isDraggingOver) setIsMinimized(true);
            }}
          >
            {isMinimized &&
              col?.tasks_order &&
              col.tasks_order.map((t, i) => {
                return (
                  <Flex key={"minimizedTask" + i} style={{ justifyContent: "center", flexGrow: "none", background: "white", height: 30, width: 45, borderRadius: 4, margin: 3 }}>
                    <img alt="check" src={check} style={{ height: 14 }} />
                  </Flex>
                );
              })}

            {!isMinimized &&
              col.tasks_order?.length > 0 &&
              col.tasks_order.map((task, k) => {
                const _task = col.tasks.find((tsk) => tsk.id === task);
                const _user = users.find((usr) => usr.id === _task.person_id);
                return <Task indexPath={[...indexPath, k]} key={[...indexPath, k].join("-")} id={_task.id} task={_task} index={k} clickTaskCb={editTaskCb} user={_user} />;
              })}
            {provided.placeholder}
            {isFirst && showAdd && !snapshot.isDraggingOver && (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginTop: 12,
                  marginLeft: 12,
                  height: 50,
                }}
              >
                <ButtonTiny
                  plus
                  active
                  callback={() => {
                    if (addTaskCb) addTaskCb(storyId, storyIndex, col.id, index);
                  }}
                />
              </div>
            )}
          </div>
        )}
      </Droppable>
    </FlexLeftColNoGap>
  );
}
